import { type Registry as Services, service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import config from 'ember-get-config';
import Service from 'ember-simple-auth/services/session';
import UserModel from 'uplisting-frontend/models/user';

interface IError {
  status: '401' | '402' | '403' | '404' | '409' | '423';
}

interface IFetchError {
  status?: number;
  errors: IError[];
}

function isErrorOfStatus(err: IFetchError, status: number): boolean {
  return (
    err.errors?.some((error) => error.status === `${status}`) ||
    err?.status === status
  );
}

export default class SessionService extends Service {
  @service router!: Services['router'];
  @service sentry!: Services['sentry'];
  @service('repositories/user') userRepository!: Services['repositories/user'];

  @cached @tracked currentUser?: UserModel;
  isAirdnaUser = false;

  public async fetchCurrentUser(): Promise<void> {
    if (this.currentUser || !this.isAuthenticated) {
      return;
    }

    try {
      const user = await this.userRepository.findRecord('me', {
        include: 'customer,permissions',
      });

      this.currentUser = user;

      this.sentry.configure({
        id: this.currentUser.id,
        email: this.currentUser.email,
      });
    } catch (err) {
      const notAuthorized = this.isAuthenticationError(err as IFetchError);
      const isConflictError = this.isConflictError(err as IFetchError);

      if (notAuthorized) {
        this.handleNotAuthorized();
      } else if (isConflictError) {
        this.router.transitionTo('account.verify');
      } else {
        this.sentry.captureException(err);
      }
    }
  }

  public handleInvalidation() {
    // no-op
    // By default this will reload the app, but we don't want that, we just want to clear local storage
  }

  public handleAuthentication(): void {
    // no-op
    // We don't want to redirect to the routeAfterAuthentication
  }

  public handleNotAuthorized() {
    this.invalidate();

    window.open(config.auth.loginUrl, '_self');
  }

  public handleLogout() {
    this.invalidate();

    window.open(config.auth.logoutUrl, '_self');
  }

  private isAuthenticationError(err: IFetchError): boolean {
    return isErrorOfStatus(err, 401);
  }

  private isConflictError(err: IFetchError): boolean {
    return isErrorOfStatus(err, 409);
  }
}

declare module '@ember/service' {
  interface Registry {
    session: SessionService;
  }
}
