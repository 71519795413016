import type Transition from '@ember/routing/transition';
import PermittedRoute from 'uplisting-frontend/pods/base/permitted-route';
import InsightsController, {
  TabOption,
} from 'uplisting-frontend/pods/insights/controller';

export default class InsightsRoute extends PermittedRoute {
  permission = 'insights';

  setupController(
    controller: InsightsController,
    model,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    controller.activeTab = transition?.to?.name.includes('insights.reports')
      ? TabOption.reports
      : TabOption.insights;
  }
}
