import { cached } from '@glimmer/tracking';
import BaseController from 'uplisting-frontend/pods/base/controller';

export default class RegisterController extends BaseController {
  @cached
  get airdna(): boolean {
    return this.router.currentRoute.queryParams['airdna'] === 'true';
  }

  @cached
  get isMeetingRoute(): boolean {
    return (this.router.currentRouteName ?? '') === 'register.meeting';
  }
}
